/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary markdown component.
 */

// Core dependencies
import React from "react";
import rehypeReact from "rehype-react";

// App dependencies
import ImageCaption from "../image-caption/image-caption";

// Styles
import compStyles from "./markdown.module.css";

class Markdown extends React.Component {

    componentDidMount() {

        this.addImageClassName();
    }

    addImageClassName = () => {

        /* Find all <img> with class "gatsby-resp-image-wrapper". */
        const imagesNl = document.querySelectorAll(".gatsby-resp-image-wrapper");

        if ( !imagesNl ) {
            return;
        }

        /* Add class name. */
        Array.from(imagesNl).map(imageEl => imageEl.classList.add(compStyles.imageZoom));
    };


    render() {
        const {children} = this.props;
        const renderAst = new rehypeReact({
            createElement: React.createElement,
            components: {
                "image-caption": ImageCaption,
            }
        }).Compiler;
        return (
            <div className={compStyles.ccContent}>{renderAst(children)}</div>
        )
    }
}

export default Markdown;
