/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary image caption component.
 *
 * This component may be used within markdown.
 * To render use: <image-caption>My caption</image-caption>.
 * The component accepts a single prop of the component's children.
 */

// Core dependencies
import React from "react";

// Styles
import compStyles from "./image-caption.module.css";

class ImageCaption extends React.Component {

    render() {
        const {children} = this.props;
        return (
            <div className={compStyles.caption}>{children}</div>
        )
    }
}

export default ImageCaption;
