/*
 * Clever Canary
 * https://clevercanary.com/
 *
 * Clever Canary content template component.
 */

// Core dependencies
import {graphql} from "gatsby";
import React from "react";

// App dependencies
import Layout from "../components/layout";
import Markdown from "../components/markdown/markdown";

// Styles
import globalStyles from "../styles/global.module.css";

// the data prop will be injected by the GraphQL query below.
export default function Template({data}) {

    const {markdownRemark} = data,
        {frontmatter, htmlAst} = markdownRemark || {},
        {description, title} = frontmatter || {};

    return (
        <Layout description={description} title={title}>
            <div className={globalStyles.wrapper}>
                <Markdown>{htmlAst}</Markdown>
            </div>
        </Layout>
    );
}

// modified to find the page by id which is passed in as context
export const query = graphql`
query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
        frontmatter {
          description
          title
        }
        htmlAst
    }
}`;
